/**
 * @file This file defined for order verification in co
 * @author Bhanuka Chathuranga
 */
import React from 'react'

function Verification() {
    return (
        <>
            <div className='container background center-items' style={{ alignItems: 'center' }}>
                <div className='paper paper-box'
                // style={{ width: '39vw' }}
                >
                    <div>
                        <div>
                            <h3 className='header uppercase spacing-1' style={{ color: '#333233b5', paddingLeft: '10px' }}>Order Verification</h3>
                        </div>
                        <div className='message  spacing-1' style={{ paddingLeft: '10px' }}>
                            <p id='message' style={{ paddingLeft: '10px' }}>Thank you <br></br> Your order is confirmed in our system. In addition please send your order via WhatsApp for verification.</p>
                        </div>
                    </div>
                    {/* <div style={{ float: 'right' }}>
                    <button id='btnWhatsapp' className='btn btn-success' onClick={sendMessage}><i className="fa fa-whatsapp" aria-hidden="true"></i> Open WhatsApp</button>
                </div> */}
                    <div className='message-footer'>
                        <button id='btnGoBack' className='btn-purchase btn-confirm' style={{ display: 'none' }}>Go Back To Order</button>
                        <button id='btnWhatsapp' className='btn-purchase btn-confirm'><i className="fab fa-whatsapp" aria-hidden="true" style={{ fontSize: '18px' }}></i>Order Verification</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verification
