/**
 * @file
 * @author Bhanuka Chathuranga
 */
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import LoginPage from './LoginPage';
import Controller from "./controller/Controller";
import CustomerOrderController from './controller/CustomerOrderContoller';
import CustomerInvoiceController from './controller/CustomerInvoiceController';

toast.configure({
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 1000,
})

function App(props) {
  const location = useLocation()

  return (
    <div id="body" className="nav-md">
      <div className="container body">
        {window.location.pathname === '/onlinepurchase' || window.location.pathname === '/onlinepurchase/confirmation'
          ? <CustomerOrderController />
          : location.pathname.startsWith('/onlineinvoice/')
            ? <CustomerInvoiceController />
            : props.isLoggedIn
              ? <Controller />
              : <LoginPage />}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.rLogin.isLoggedIn,
  language: state.rLogin.language,
  error: state.rLogin.error,
})


export default connect(mapStateToProps)(App);
