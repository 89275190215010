/**
 * @file This file defined for customer order confirmation page
 * @author Bhanuka Chathuranga
 */
import React, { useEffect } from 'react'
import { MOBILE_NO, COOKIE_MAX_AGE } from '../../utils';

/**
 * whatsapp order confirm
 */
const sendMessage = async () => {
    document.getElementById('message').innerHTML = 'Thank you for confirming the order...!'
    document.getElementById('btnWhatsapp').remove()
    document.getElementById('btnGoBack').style.display = 'inline-block'
    // console.log('check mobile version');
    let order = await localStorage.getItem('order')
    let whatsappMsg = await localStorage.getItem('msg')

    var orderObj = await JSON.parse(order)
    let orderItemsStr = await (await Promise.all(await orderObj.orderItems.map((item, i) => { return `${i + 1}. ${item.desc} - ${item.qty}` }))).toString().replaceAll(',', '%0a')
    // let msg = `send?phone=${MOBILE_NO}&text=Do not alter the following details. Any changes please send separate message* %0aOrder No: ${orderObj?.orderId}%0aName: ${orderObj.name}%0a${orderItemsStr}`
    let msg = `send?phone=${MOBILE_NO}&text=${whatsappMsg}`.replace('{orderId}', orderObj.orderId).replace('{name}', orderObj.name).replace('{orderItemsStr}', orderItemsStr)
    localStorage.removeItem('token')
    localStorage.removeItem('order')
    // localStorage.setItem('details',order)
    // delete previous cookie
    document.cookie = `name=;expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    document.cookie = `address=;expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    document.cookie = `telephone=;expires=Thu, 01 Jan 1970 00:00:00 UTC;`
    // create new cookie
    document.cookie = `name=${orderObj.name};max-age=${COOKIE_MAX_AGE};secure;samesite=lax`
    document.cookie = `address=${orderObj.address};max-age=${COOKIE_MAX_AGE};secure;samesite=lax`
    document.cookie = `telephone=${orderObj.telephone};max-age=${COOKIE_MAX_AGE};secure;samesite=lax`

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        // true for mobile device
        // document.write("mobile device");
        console.log('mobile');
        window.open(`whatsapp://${msg}`)
    } else {
        // false for not mobile device
        // document.write("not mobile device");
        console.log('not mobile');
        window.open(`https://web.whatsapp.com:/${msg}`)
    }
}

const goBack = props => {
    // var origin = window.location.origin
    // window.location.replace(`${origin}/customerOrder`)
    props.history.push('/onlinepurchase')
}

const Confirmation = props => {

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            var origin = window.location.origin
            window.location.replace(`${origin}/onlinePurchase`)
        }
    }, [])

    return (
        <>
            <div className='container background center-items' style={{ alignItems: 'center' }}>
                <div className='paper paper-box'
                // style={{ width: '39vw' }}
                >
                    <div>
                        <div>
                            <h3 className='header uppercase spacing-1' style={{ color: '#333233b5', paddingLeft: '10px' }}>Order Confirmation</h3>
                        </div>
                        <div className='message  spacing-1' style={{ paddingLeft: '10px' }}>
                            <p id='message' style={{ paddingLeft: '10px' }}>Thank you <br></br> Your order is confirmed in our system. In addition please send your order via WhatsApp for verification.</p>
                        </div>
                    </div>
                    {/* <div style={{ float: 'right' }}>
                        <button id='btnWhatsapp' className='btn btn-success' onClick={sendMessage}><i className="fa fa-whatsapp" aria-hidden="true"></i> Open WhatsApp</button>
                    </div> */}
                    <div className='message-footer'>
                        <button id='btnGoBack' className='btn-purchase btn-confirm' style={{ display: 'none' }} onClick={() => goBack(props)}>Go Back To Order</button>
                        <button id='btnWhatsapp' className='btn-purchase btn-confirm' onClick={sendMessage}><i className="fab fa-whatsapp" aria-hidden="true" style={{ fontSize: '18px' }}></i> Open WhatsApp</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Confirmation;