/**
 * @file This file defined for customer order controller
 * @author Bhanuka Chathuranga
 */
import React from 'react';
import { Route } from 'react-router';

import { CustomerOrder } from '../container/views/CustomerOrder/CustomerOrder';
import Confirmation from '../container/views/CustomerOrder/Confirmation';
import Verification from '../container/views/CustomerOrder/Verification';

export const CustomerOrderController = props => {

    return (
        <>
            <Route path="/onlinepurchase" exact component={CustomerOrder} />
            <Route path="/onlinepurchase/confirmation" component={Confirmation} />
            <Route path="/onlinepurchase/verification" component={Verification} />
        </>
    )
}

export default CustomerOrderController
